<template>
    <div>
        <v-icon small class="mr-2" @click="updateMenu" v-if="checkUserPermission('EDIT')">
            mdi-pencil
        </v-icon>
        <v-icon color="red" small
                @click="dialogDelete = true"
                :disabled="dataDelete.length > 0" v-if="checkUserPermission('DELETE')">
            mdi-delete
        </v-icon>
        <dialog-delete :darkmode="darkmode"
                       :dialog-delete="dialogDelete"
                       v-on:closeDelete="cancelDelete"
                       v-on:deleteItemConfirm="deleteItem" />
    </div>
</template>

<script>
    import { mapActions } from "vuex";

    export default {
        components: {
            DialogDelete: () => import('../../../../components/shared/notify/dialogDelete')
        },
        props: ['darkmode', "item", "params", "dataDelete", "userPermission"],
        data() {
            return {
                dialogDelete: false,
            };
        },
        methods: {
            async updateMenu() {
                await this.fetchCurrentMenu(this.item);
                await this.fetchListPermission();
                await this.fetchParentMenu();
                await this.fetchPopUp(true);
            },
            cancelDelete() {
                this.dialogDelete = false
            },
            async deleteItem() {
                await this.deleteMenu({
                    vm: this,
                    data: { deleteItems: [{ _id: this.item._id }] }
                });
                await this.fetchListMenus(this.params)
                this.dialogDelete = false;
            },
            ...mapActions("$_menu", ["fetchPopUp", "fetchListMenus", "deleteMenu", "fetchCurrentMenu", 'fetchParentMenu']),
            ...mapActions("$_permission", ["fetchListPermission"]),
        },

    };
</script>

<style lang="scss" scoped>
</style>
